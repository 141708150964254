






















import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUott1721M6_Task23',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: 'a) Les cétones sont des électrophiles plus réactifs que les aldéhydes, puisqu’il existe un effet stérique moins important des groupes adjacents',
          value: 'wrongKetone2',
        },
        {
          text: 'b) Les aldéhydes sont des électrophiles plus réactifs que les cétones, puisqu’il existe un effet stérique moins important des groupes adjacents',
          value: 'b',
        },
        {
          text: 'c) Les cétones sont des électrophiles plus réactifs que les aldéhydes, puisqu’il existe un effet inductif positif des chaînes alkyles adjacentes',
          value: 'wrongKetone1',
        },
        {
          text: 'd) Les aldéhydes sont des électrophiles plus réactifs que les cétones, puisqu’il existe un effet inductif positif des chaînes alkyles adjacentes',
          value: 'wrongAldehyde',
        },
      ],
    };
  },
};
